.message {
  font-family: monospace;
  font-size: 16px;
}

.safari-message {
  display: none;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  &.active {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.firefox-message {
  display: none;
  font-size: 16px;

  &.active {
    display: block;
    position: absolute;
    top: 30px;
    left: 0;
    width: 100%;
    text-align: center;
    background-color: white;
    z-index: 10;
  }

  code {
    background-color: rgb(232, 232, 232);
    border-radius: 2px;
    padding: 0px 2px;
  }
}

.skit-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  width: 100%;
  min-height: 100vh;
  padding: 20px;
  font-size: 16px;

  &.hidden {
    display: none;
    visibility: hidden;
  }
}

.skit-video-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 14%;
  height: 120px;

  @include media-query($break-small) {
    width: 25%;
  }

  .skit-absolute-maker {
    position: relative;
    width: 100px;
    height: 75px;
    z-index: 0;
    mix-blend-mode: multiply;

    &:hover {
      z-index: 1;
      mix-blend-mode: multiply;
      transform: scale(5);

      .skit-color-bg {
        display: none;
      }

      video {
        // transform: scale(5);
        z-index: 1;
        mix-blend-mode: none;
      }
    }
  }

  .skit-color-bg {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    &.red {
      background-color: rgb(189, 35, 35);
    }

    &.blue {
      background-color: rgb(19, 51, 168);
    }
  }

  video {
    position: relative;
    width: 100px;
    transform: scale(1);
    z-index: 0;
    mix-blend-mode: multiply;
  }
}

.skit-footer {
  display: flex;
  justify-content: center;
  position: absolute;
  width: 100%;
  bottom: 40px;
  font-size: 16px;
  left: 0;
  color: black;
  text-decoration: underline;
  font-family: monospace;

  .skit-footer-left {
    margin-right: 100px;
  }

  a, a:visited {
    color: black;
    cursor: pointer;
    text-decoration: underline;

    &:hover {
      opacity: 0.7;
    }
  }
}

.skit-about-trigger {
  display: inline !important;
  color: black;
  cursor: pointer;
  text-decoration: underline;

  &:hover {
    opacity: 0.7;
  }
}


.skit-about {
  display: none;
  position: fixed;
  z-index: 200;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: white;
  font-family: monospace;
  color: black;

  p {
    margin: 0;
  }

  &.active {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  span {
    display: block;
    width: 100%;
    max-width: 400px;
    margin-bottom: 50px;
  }
}



