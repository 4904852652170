.site-footer {
  @include flexbox;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: relative;
  height: 90vh;
  color: $dark-color;
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.2s;
  padding: 220px $spacing-unit 170px;
  z-index: 20;

  @include media-query($break-medium){
    padding: 220px $spacing-unit*2 150px;
  }

  @include media-query($break-small){
    padding: 100px $spacing-unit 70px;
    position: relative;
    width: 100%;
    height: auto;
  }

  &.visible {
    opacity: 1;
    pointer-events: all;
  }

  .footer-content {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;

    @include media-query($break-medium){
      flex-direction: column;
    }
  }
}

.footer-column-ception {
  width: 100%;

  @include media-query($break-medium){
    width: 100%;
  }

  @include media-query($break-small){
    width: 100%;
  }

  @media screen and (max-width: $break-small) and (orientation: landscape){
    width: 50%;
  }
}

.footer-left-column {
  width: 270px;
  font-family: $sans-serif-font;
  font-size: 20px;
  line-height: 28px;
  @include flexbox;
  justify-content: flex-start;
  flex-direction: column;

  @include media-query($break-medium){
    flex-direction: row;
    width: 100%;
    margin: 0 auto;
    padding: 0;
    -webkit-column-count: 2;
    -moz-column-count: 2;
    column-count: 2;
  }

  @include media-query($break-small){
    flex-direction: column;
    width: 100%;
    margin: $spacing-unit*2 auto;
    font-size: 18px;
    -webkit-column-count: 1;
    -moz-column-count: 1;
    column-count: 1;
  }

  @media screen and (max-width: $break-small) and (orientation: landscape){
    flex-direction: row;
  }

  a, a:visited {
    color: $dark-color;
    text-decoration: none;

    &:hover {
      color: $dark-color;
      text-decoration: underline;
    }
  }
}

.contributor-link {
  font-family: $serif-font;
  font-size: 52px;
  color: $light-color;
  text-decoration: none;
  float: right;
  //margin: $spacing-unit 0 0;

  &:hover {
    text-decoration: underline;
  }

  @include media-query($break-medium) {
    float: left;
    margin: $spacing-unit*2 0 0 -30%;
  }

  @include media-query($break-small){
    float: left;
    margin: $spacing-unit auto;

    &:hover {
      text-decoration: none;
    }
  }
}

.footer-right-column {
  width: 100%;
  max-width: 800px;
  font-family: $serif-font;
  font-size: 26px;
  margin: 0 0 0 $spacing-unit*6;

  @include media-query($break-medium){
    width: 100%;
    margin: $spacing-unit*2 auto 0;
    font-size: 22px;
  }

  @include media-query($break-small){
    margin: $spacing-unit auto $spacing-unit*2;
    font-size: 20px;
  }

  p {
    -webkit-margin-before: 0em;
    -webkit-margin-after: 0em;
    margin: 0 0 $spacing-unit;
  }
}
