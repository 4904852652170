
.not-found {
  display: block;
  text-decoration: none;
  width: 100%;
  height: 100vh;
  background-image: url(../assets/uploads/page404.jpg);
  background-size: cover;
  background-position: center top;

  @include media-query(640px){
    background-image: url(../assets/uploads/page404-mobile.jpg);
    background-position: center center;
  }
}
