.contributors {
  background-color: $dark-color;
  min-height: 100vh;
}

.contributors-content {
  width: 80%;
  margin: 0 30% 0 0;
  color: $light-color;
  padding: $spacing-unit*2 $spacing-unit*2;
  @include flexbox;
  flex-wrap: wrap;
  justify-content: flex-start;

  @include media-query($break-medium){
    width: 90%;
    //flex-direction: column;
    padding: $spacing-unit $spacing-unit;
  }

  @include media-query($break-small){
    flex-direction: column;
    padding: $spacing-unit/2;
    margin-top: 120px;
  }
}


.contributor-container {
  display: block;
  width: 48%;
  max-width: 350px;
  margin: 0 0 $spacing-unit 0;
  padding-right: 20px;

  @include media-query($break-small){
    width: 100%;
    margin: 0 0 $spacing-unit;
  }

  h2, h2 a {
    margin: 0 0 $spacing-unit/4;
    font-family: $serif-font;
    font-size: 26px;

    @include media-query($break-small) {
      font-size: 24px;
    }
  }

  h2 a img {
    display: inline-block;
    height: 22px;
    margin-left: 4px;
  }

  h3 {
    margin: 0 0 $spacing-unit/3;
    font-family: $sans-serif-font;
    font-size: 20px;

    @include media-query($break-small) {
      font-size: 18px;
    }
  }

  a, a:visited {
    font-family: $sans-serif-font;
    font-size: 20px;
    color: $light-color;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
}
