/*
        experiments
*/
.opexp-list-container {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  width: 100%;

  @include media-query($break-small) {
    margin-top: 120px;
  }
}

.opexp-list-entry {
  width: 20%;
  padding: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  display: flex;

  @include media-query($break-large) {
    width: 25%;
  }

  @include media-query($break-medium) {
    width: 33%;
  }

  @include media-query($break-small) {
    width: 100%;
    padding: 20px;    
  }

  a {
    flex: 1;
    display: block;
    width: auto;
    position: relative;
    
    img {
      display: block;
      width: 100%;
    }

  } 

  .opexp-thumbnail-display-on-hover {
    display: none;
  }

  a:hover {
    z-index: 2;

    & + .opexp-list-data {
      display: flex;
      z-index: 1;

      @include media-query($break-small) {
        display: none;
      }
    }

    .opexp-thumbnail-hide-on-hover {
      display: none;
    }

    .opexp-thumbnail-display-on-hover {
      display: initial;
    }
  }

  .opexp-list-data {
    display: none;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: $light-color;
    justify-content: center;
    align-items: center;
  }

  .exp-data {
    width: 500px;
  }

}

.full-width {
  width: 100%;
}

.centered-pic {
  display: flex;
  justify-content: center;
  align-items: center;
}

.exp-data {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}


.opexp-data-header {
  display: flex;
  align-self: stretch;
  
  .number {
    flex: 1;
  }
}

.experiment-page-container {
  width: 100%;
  padding: $spacing-unit*2;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;

  @include media-query($break-medium) {
    flex-direction: column;
    overflow: hidden;
  }

  .experiment-visuals {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: stretch;
    
    @include media-query($break-large) {
      padding-right: $spacing-unit/2;
    }

    @include media-query($break-medium) {
      padding-right: 0;
    }

    .exp-images {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      align-self: stretch;
      position: relative;
    
      img {
        display: block;
        width: 80%;
        position: absolute;

        @include media-query($break-large) {
          width: 90%;
        }

        @include media-query($break-medium) {
          width: 100%;
        }
      }
    }
  }

  .experiment-data {
    width: 500px;
    margin-left: $spacing-unit;
    display: flex;
    flex-direction: column;
    align-items: stretch;

    @include media-query($break-large) {
      width: 430px;
    }

    @include media-query($break-medium) {
      width: 100%;
      max-width: 500px;

      .exp-info-content {
        opacity: 0;
        pointer-events: none;

        &.visible {
          opacity: 1;
          pointer-events: all;
          background-color: $light-color;
          display: flex;
          z-index: 500; // High number because image pilling is using z-index to work
          position: fixed;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          justify-content: center;
          align-items: center;
          padding-bottom: 120px;
          overflow-y: scroll;
          -webkit-overflow-scrolling: touch;
        }
      }
    }

    .arrow-links {
      margin: $spacing-unit*1.5 0 0;
      display: flex;
      justify-content: space-between;
      align-items: center;

      @include media-query($break-medium) {
        position: relative;
        z-index: 501;
      }

      .toggle-exp-data {
        display: none;
        @include media-query($break-medium) {
          display: block;
          cursor: pointer;
        }
      }
    }
  }
}

.opexp-nav {
  text-decoration: none;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  img {
    display: block;
    width: 40px;
    height: 40px;

    @include media-query($break-large) {
      width: 30px;
      height: 30px;
    }
  }
  .btn-txt {
    display: none;
    position: absolute;
    pointer-events: none;
    color: $dark-color;
    white-space: nowrap;

    &.next, &.close {
      right: 0;
    }

    &.prev {
      left: 0;
    }

  }

  &:hover {
    img {
      display: none;
      @include media-query($break-medium) {
        display: block;
      }
    }
    .btn-txt {
      display: block;

      @include media-query($break-medium) {
        display: none;
      }
    }
  }
}





/*
       references
*/

.two-column-container {
  display: flex;
  justify-content: space-between;

  @include media-query ($break-medium) {
    flex-direction: column;
  }
  
  @include media-query ($break-small) {
    margin-top: 120px;
  }

  .left-column, .right-column {
    width: 48%;

    @include media-query ($break-medium) {
      width: 100%;
    }
  }

  .section {
    margin-bottom: $spacing-unit;
  }

  .title {
    font-family: $sans-serif-font;
    font-size: 62px;
    text-transform: uppercase;
    margin-bottom: $spacing-unit/4;
  }

  .content {
    font-family: $sans-serif-font;
    font-size: 24px;
    line-height: 1.4;

    ul, p {
      padding: 0;
      margin: 0;
    }

    p:not(:first-child) {
      margin-top: $spacing-unit/2;
    }

    li {
      list-style: none;
      
    }
  }
}