@import "op/fonts";

$serif-font: 'CrimsonText', Times, serif !default;
$sans-serif-font: 'MadrasBold', Helvetica, Arial, sans-serif !default;
$title-font: 'MadrasRegular', Helvetica, Arial, sans-serif !default;

//$dark-color: #121212;
$dark-color: #16181a;
$light-color: #fff;

$spacing-unit: 30px;


// Width of the content area
$content-width:    800px !default;

$break-large:     1450px;
$break-medium:     1050px;
$break-small:      670px;

@mixin border-radius($radius) {
    -webkit-border-radius: $radius;
    -moz-border-radius: $radius;
    -ms-border-radius: $radius;
    border-radius: $radius;
}

@mixin box-shadow($shadow) {
    -webkit-box-shadow: $shadow;
    -moz-box-shadow: $shadow;
    -ms-box-shadow: $shadow;
    box-shadow: $shadow;
}

@mixin block-shadow {
    -webkit-box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.5);
    -moz-box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.5);
    -ms-box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.5);
    box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.5);
}

@mixin box-sizing($sizing) {
    -webkit-box-sizing: $sizing;
    -moz-box-sizing: $sizing;
    -ms-box-sizing: $sizing;
    box-sizing: $sizing;
}

@mixin transform($transform) {
    -webkit-transform: $transform;
    -moz-transform: $transform;
    -ms-transform: $transform;
    transform: $transform;
}

@mixin flexbox {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flexbox;
    display: flex;
}

// Use media queries like this:
// @include media-query($on-palm) {
//   .wrapper {
//     padding-right: $spacing-unit / 2;
//     padding-left: $spacing-unit / 2;
//   }
// }
@mixin media-query($device) {
  @media screen and (max-width: $device) {
    @content;
  }
}

* {
  box-sizing: border-box;
}

h1, h2, h3, h4, h5, h6 {
  font-weight: normal;
}

body {
  -webkit-text-size-adjust: 100%;
  -webkit-font-smootthing: antialiased;
  -moz-osx-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  -webkit-font-feature-settings: "kern" 1;
     -moz-font-feature-settings: "kern" 1;
       -o-font-feature-settings: "kern" 1;
          font-feature-settings: "kern" 1;
  font-kerning: normal;
  margin: 0;
  padding: 0;
  transition: background-color 0.3s;
  font-family: $serif-font;
  font-size: 20px;
}

.dark-bg {
  background-color: $dark-color;
}

a, a:visited {
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}

.dark-text {
  color: $dark-color;

  a, a:visited, a:hover {
    color: $dark-color;
  }
}

.white-text {
  color: $light-color;
  
  a, a:visited, a:hover {
    color: $light-color;
  }
}

.page-title {
  font-family: $serif-font;
  font-size: 102px;
  position: fixed;
  top: 40px;
  right: 40px;
  cursor: pointer;
  z-index: 100;
  display: block;

  @include media-query($break-large) {
    font-size: 82px;
  }

  @include media-query($break-small) {
    font-size: 62px;
  }

  .title, .title-back-button {
    position: absolute;
    top: 0;
    right: 0;
    text-decoration: none;
  }

  .title-back-button {
    opacity: 0;
    font-size: 40px;
    top: 10px;
    right: 10px;
  }

  &:hover {
    .title {
      opacity: 0;
    }
    .title-back-button {
      opacity: 1;
    }
  }
}

blockquote {
  padding: 0 0 0 $spacing-unit/2;
  font-style: italic;
  opacity: 0.5;
  margin: 0 0 $spacing-unit/2;
  border-left: solid 2px rgba(255,255,255,1);
}

.svg-fill-dark {
  fill: $dark-color;
}

.svg-fill-light {
  fill: $light-color;
}


.landing-video {
  width: 100%;
  background-color: $dark-color;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  @include flexbox;
  justify-content: center;
  align-items: center;

  .video-container {
    margin: 0 !important;
    padding: 0;
  }

  a, .landing-enter-link {
    display: block;
    width: 0;
    height: 0;
    position: fixed;
    right: 50%;
    bottom: 50px;
    left: 50%;
    text-align: center;
    font-family: $serif-font;
    font-size: 20px;
    transform: translateX(-30px);

    &:hover {
      text-decoration: none;
    }
  }
}

.landing-enter-link {
  display: block;
  width: 0;
  height: 0;
  position: fixed;
  right: 50%;
  bottom: 50px;
  left: 50%;
  text-align: center;
  font-family: $serif-font;
  font-size: 20px;
  transform: translateX(-30px);
  color: $dark-color !important;

  &:hover {
    text-decoration: none;
  }
}

.landing-generic {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 100vh;
  background-color: $dark-color;
}

.landing-snap-it {
  background-color: black;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.snap-it-cover {
  display: block;
  width: 90%;
}

.enter-link {
  color: black;
  font-family: $sans-serif-font;
  padding-bottom: $spacing-unit;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  padding-bottom: $spacing-unit;
  text-align: center;

  &.white {
    color: white;
  }

  &.black {
    color: $dark-color;
  }
}

.small-logo {
  width: 30%;
  max-width: 500px;
  top: $spacing-unit;
  right: 6%;
}

.x-small-logo {
  max-width: 150px;
  top: $spacing-unit;
  right:  $spacing-unit;
}

.small-logo, .x-small-logo {
  display: block;
  position: fixed;

  .logo {
    width: 100%;
    display: none;
  }

  .logo-one {
    display: block;
  }
}


.page-generic-container {
  width: 85%;
  padding: 40px;

  @include media-query($break-small) {
    width: 100%;
  }
}


.data-display {
  display: block;
  text-align: left;

  .number {
    font-family: $sans-serif-font;
    font-size: 70px;
    margin: 0 0 $spacing-unit*1.5;
  }

  .name {
    font-family: $sans-serif-font;
    font-size: 32px;
    margin: 0;
  }

  .author {
    font-family: $serif-font;
    font-size: 36px;
    line-height: 1.1;
    margin: 0 0 $spacing-unit*1.5;
    
    span {
      font-size: 32px;
      font-family: $sans-serif-font;
    }    

    a, a:visited {
    //   color: white;
      text-decoration: none;
    }

    a:hover {
      text-decoration: underline;
    }
  }

  table {
    font-family: $sans-serif-font;
    font-size: 20px;

    tr {
      vertical-align: top;
    }

    td {
      line-height: 28px;
      padding: 0 $spacing-unit/2 0 0;

      p {
        margin: 0;
      }
    }
  }


  @include media-query($break-medium){

    .number {
      font-size: 42px;
      margin: 0 0 $spacing-unit*1;
    }

    .name {
      font-size: 20px;
    }

    .author {
      font-size: 24px;
      margin: 0 0 $spacing-unit*1;

      span {
        font-size: 20px;
      }
    }

    table {
      font-size: 18px;
    }
  }

}


// Import partials.
@import
  "op/home",
  "op/project",
  "op/contributors",
  "op/footer",
  "op/teaser",
  "op/404",
  "op/video",
  "op/op005-skit",
  "op/pages",

  "op/landing-pages"
;
