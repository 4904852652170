
.teaser-home {
  display: block;
  text-decoration: none;
  width: 100%;
  height: 100vh;
  background-image: url(../assets/uploads/mockup-sektop-site.jpg);
  background-size: cover;
  background-position: center bottom;
  font-size: 30px;
  font-family: 'MadrasBold';
  color: white;
  text-align: center;
  padding-top: 50vh;
  cursor: pointer;

  @include media-query (640px) {
    width: 100%;
    height: 100vh;
    background-image: url(../assets/uploads/mockup-site.jpg);
    background-size: cover;
    background-position: center center;
  }
}
