.project-header,
.project-header-video {
  width: 100%;
  height: 70px;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  @include flexbox;
  justify-content: center;
  z-index: 15;

  &.white {
    .svg-fill-dark {
      fill: $light-color;
    }
  }
}

.back-home-link {
  //position: fixed;
  @include flexbox;
  flex-direction: column;
  justify-content: center;
  width: 240px;
  height: 70px;
  margin: 0px auto;

  .logo {
    width: 100%;
    display: none;
  }

  .logo-one {
    display: block;
  }

  &.white {
    .svg-fill-dark {
      fill: $light-color;
    }
  }
}



.project-fake-margin {
  width: 100%;
  height: $spacing-unit*4;

  &.small {
    height: 0;
  }
}

.project-page-content {
  width: 100%;
  height: auto;
  margin: 0 auto 0;
}

.project-image {
  display: block;
  width: 55%;
  margin: $spacing-unit*8 auto 0;

  &:nth-child(2) {
    margin: 0 auto 0;
  }

  @include media-query($break-medium){
    width: 70%;
    margin: $spacing-unit*6 auto 0;
  }

  @include media-query($break-small){
    width: 70%;
    margin: $spacing-unit*4 auto 0;
  }
}

.project-description-container {
  @include flexbox;
  justify-content: center;
  align-items: center;
  position: relative;
  color: $light-color;
  height: 100vh;
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.2s;
  padding: 300px $spacing-unit 250px;
  z-index: 10;
  margin-top: 80px;

  &.visible {
    opacity: 1;
    pointer-events: all;
  }

  @include media-query($break-large){
    height: auto;
    padding: 350px $spacing-unit*2 250px;
  }

  @include media-query($break-medium){
    padding: 220px $spacing-unit*2 150px;
  }

  @include media-query($break-small){
    padding: 100px $spacing-unit 80px;
  }
}

.project-column-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;

  @include media-query($break-medium){
    flex-direction: column;
  }
}

.relative {
  position: relative;
}

.column-ception {
  width: 100%;

  @include media-query($break-medium) {
    width: 50%;
    float: left;
  }

  @include media-query($break-small) {
    width: 100%;
  }
}

.project-metadatas  {
  width: 370px;
  z-index: 10;
  opacity: 1;
  pointer-events: all;
  margin: 0 0 0;

  @include media-query($break-medium) {
    // display: flex;
    // flex-direction: column;
    width: 100%;
  }
}

.release-author {
  line-height: 1;
}

.buy-link, .buy-link:visited {
  width: auto;
  height: auto;
  background-color: transparent;
  border: none;
  cursor: pointer;
  font-family: $serif-font;
  font-size: 52px;
  color: $light-color;
  text-decoration: none;
  position: absolute;
  bottom: -120px;
  left: 0;
  //margin: $spacing-unit 0 0;

  // &:hover {
  //   text-decoration: underline;
  // }

  @include media-query($break-medium) {
    position: static;
    float: left;
    margin: $spacing-unit*2 0 0 -30%;
  }

  @include media-query($break-small){
    float: left;
    margin: $spacing-unit auto;

    &:hover {
      text-decoration: none;
    }
  }
}

.project-page-description {
  width: 100%;
  max-width: 800px;
  font-family: $serif-font;
  font-size: 26px;
  //line-height: 43px;
  margin: 0 0 0 $spacing-unit*4;

  @include media-query($break-medium){
    width: 100%;
    margin: $spacing-unit*2 0 0 0;
    font-size: 22px;
  }

  @include media-query($break-small){
    width: 100%;
    margin: $spacing-unit 0 0 0;
    font-size: 20px;
  }

  a {
    text-decoration: none;
    position: relative;

    &::after {
      content: "";
      display: block;
      position: absolute;
      bottom: 0;
      right: 0;
      left: 0;
      height: 1px;
      background-color: $light-color;
    }

    &:hover {
      &:after {
        display: none;
      }
    }
  }

  p {
    -webkit-margin-before: 0em;
    -webkit-margin-after: 0em;
    margin: 0 0 $spacing-unit/2;
  }
}
