.logo-fixed-cover {
  position: fixed;
  @include flexbox;
  flex-direction: column;
  justify-content: center;
  z-index: 0;
  top: 0;
  right: 0;
  left: 0;
  height: 100vh;

  .logo {
    width: 100%;
    display: none;
  }

  .logo-one {
    display: block;
  }
}

.baseline {
  position: absolute;
  width: 100%;
  z-index: 5;
  text-align: center;
  //bottom: 150px;
  top: 80%;
  font-size: 20px;
  color: $light-color;
  opacity: 1;
  transition: opacity 0.2s;
  font-family: $sans-serif-font;
  //color: #8a8a8a;

  @include media-query($break-medium) {
    bottom: 80px;
  }

  @include media-query($break-small){
    font-size: 16px;
  }

  &.hidden {
    opacity: 0;
  }
}

.home-content {
  margin: 0 auto;
  position: relative;
  z-index: 10;
  text-align: center;
  font-family: $sans-serif-font;

  @include media-query($break-medium) {
    margin: 0 auto;
  }

  @include media-query($break-small) {
    margin: 0 auto -80px;
  }

  .content-fake-padding {
    width: 100%;
    height: 100vh;
  }

  a {
    color: $dark-color;
    text-decoration: none;

    &:hover {
      color: $dark-color;
      text-decoration: none;
    }

    &:active {
      color: $dark-color;
      text-decoration: none;
    }
  }
}


.release-container {
  width: 100%;
  //height: 100vh;
  // max-height: 1300px;
  // min-height: 600px;
  padding: 300px 0;
  position: relative;
  @include flexbox;
  justify-content: center;
  align-items: center;

  @include media-query($break-large){
    height: auto;
    padding: 180px 0;
  }

  @include media-query($break-medium){
    height: auto;
    padding: 120px 0;
  }

  @include media-query($break-small){
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 80px 0;
  }

  &:nth-child(even) {
    flex-direction: row;
  }

  &:nth-child(odd) {
    flex-direction: row-reverse;
  }

  .description-background {
    position: fixed;
    top: 0;
    right:0;
    bottom: 0;
    left: 0;
    background-color: $light-color;
    z-index: 0;
    opacity: 0;
    pointer-events: none;

    @include media-query($break-medium){
      display: none;
    }
  }

  &:nth-child(even) {
    .release-description-container-home {
      padding: 0 0 0 $spacing-unit*6;
    }
  }

  &:nth-child(odd) {
    .release-description-container-home {
      padding: 0 $spacing-unit*4 0 $spacing-unit;
    }
  }
}

.mobile-release-title {
  display: none;

  @include media-query($break-medium){
    display: block;
    color: $light-color;
    width: 100%;
    text-align: center;
    font-size: 32px;
  }

  @include media-query($break-small){
    font-size: 22px;
  }
}





.release-description-container-home {
  position: relative;
  z-index: 5;
  opacity: 0;
  pointer-events: none;
  width: 500px;

  @include media-query($break-medium){
    display: none;

  }
}

.release-link {
  display: block;
  position: relative;
  z-index: 10;
  width: 40%;
  height: auto;
  //height: 60vh;
  @include transform(rotate(22deg));

  @include media-query($break-medium){
    align-self: stretch;
    width: 80%;
  }

  @include media-query($break-small){
    align-self: stretch;
    width: 60%;

  }

  img {
    width: 100%;

    &.black {
      display: none;
    }
    &.white {
      display: block;
    }
  }

  &:hover {
    img {
      &.black {
        display: block;

        @include media-query($break-medium){
          display: none;
        }
      }
      &.white {
        display: none;

        @include media-query($break-medium){
          display: block;
        }
      }
    }

    + .description-background {
      opacity: 1;

      + .release-description-container-home {
        opacity: 1;
        pointer-events: all;
      }
    }
  }
}





.site-footer {
  @include flexbox;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: relative;
  height: 90vh;
  color: $dark-color;
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.2s;
  padding: 220px $spacing-unit 170px;
  z-index: 20;

  @include media-query($break-large){
    padding: 220px $spacing-unit*4 170px;
  }

  @include media-query($break-medium){
    padding: 220px $spacing-unit*2 150px;
  }

  @include media-query($break-small){
    padding: 100px $spacing-unit 70px;
    position: relative;
    width: 100%;
    height: auto;
  }

  &.visible {
    opacity: 1;
    pointer-events: all;
  }

  .footer-content {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;

    @include media-query($break-medium){
      flex-direction: column;
    }
  }
}

.footer-column-ception {
  width: 100%;

  @include media-query($break-medium){
    width: 100%;
  }

  @include media-query($break-small){
    width: 100%;
  }

  @media screen and (max-width: $break-small) and (orientation: landscape){
    width: 50%;
  }
}

.footer-links {
  width: 280px;
  min-width: 280px;
  font-family: $serif-font;
  font-size: 20px;
  line-height: 28px;
  @include flexbox;
  justify-content: flex-start;
  flex-direction: column;

  @include media-query($break-medium){
    flex-direction: row;
    justify-content: space-around;
    width: 100%;
    margin: 0 auto;
    padding: 0;
    -webkit-column-count: 2;
    -moz-column-count: 2;
    column-count: 2;
  }

  @include media-query($break-small){
    flex-direction: column;
    width: 100%;
    margin: $spacing-unit auto;
    font-size: 18px;
    -webkit-column-count: 1;
    -moz-column-count: 1;
    column-count: 1;
  }

  @media screen and (max-width: $break-small) and (orientation: landscape){
    flex-direction: row;
  }

  a, a:visited {
    color: $dark-color;
    text-decoration: none;

    &:hover {
      color: $dark-color;
    }
  }
}

.homepage-footer-link {
  font-size: 52px;
  margin-bottom: $spacing-unit*2;

  &:nth-child(even) {
    align-self: flex-start;
  }

  &:nth-child(odd) {
    align-self: flex-end;
  }

  @include media-query($break-medium) {
    margin-left: 0;
  }

  @include media-query($break-small) {
    font-size: 42px;
    margin: 0 $spacing-unit*2 $spacing-unit;
  }

  &:hover {
    text-decoration: none;
  }

}



.contributor-link {
  font-family: $serif-font;
  font-size: 52px;
  color: $light-color;
  text-decoration: none;
  float: right;
  //margin: $spacing-unit 0 0;

  &:hover {
    text-decoration: underline;
  }

  @include media-query($break-medium) {
    float: left;
    margin: $spacing-unit*2 0 0 -30%;
  }

  @include media-query($break-small){
    float: left;
    margin: $spacing-unit auto;

    &:hover {
      text-decoration: none;
    }
  }
}

.footer-description {
  width: 100%;
  max-width: 800px;
  font-family: $serif-font;
  font-size: 26px;
  margin-right: $spacing-unit*4;

  @include media-query($break-medium){
    width: 100%;
    margin: $spacing-unit*2 auto 0;
    font-size: 22px;
  }

  @include media-query($break-small){
    margin: $spacing-unit auto 0;
    font-size: 20px;
     p {
      margin: 0;
     }
  }

  p {
    -webkit-margin-before: 0em;
    -webkit-margin-after: 0em;
    margin: 0 0 $spacing-unit;
  }
}