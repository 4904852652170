.video-container {
  width: 80%;
  margin: 0 auto;
  padding: $spacing-unit*3 0 0;

  @include media-query($break-small){
    padding: $spacing-unit*6 0 $spacing-unit*2;
  }

  &::after {
    content: "";
    display: block;
    position: fixed;
    pointer-events: none;
    z-index: -10;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: $dark-color;
    opacity: 1;
    transition: opacity 0.3s;
  }

  &.hidden {
    &::after {
      opacity: 0;
    }
  }
}

.embed-container {
  // width: 60%;
  // margin: 0 auto;
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
  max-width: 100%;

  iframe, object, embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}


.video-container-opexp {
  width: 95%;
  margin: 0 auto;
  padding: 0 0;

  .embed-container {
    padding-bottom: 62.25%;
  }
}





// This is for the lab page


.fs-video {
  // @include flexbox;
  // justify-content: center;
  // align-items: center;
  height: 100vh;
  // width: 100%;
  background-color: black;
}

.video-container-fs {
	position: relative;
	padding-bottom: 56.25%;
	padding-top: 30px;
	height: 0;
	overflow: hidden;
}

.video-container-fs iframe,
.video-container-fs object,
.video-container-fs embed {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}
