.landing-link {
  // height: 120px;
  padding-bottom: $spacing-unit*2;
  display: flex;
  justify-content: center;
  align-items: center;
  color: $light-color;

  a, a:visited {
    color: $light-color;
    margin: 0 $spacing-unit;
    display: inline-block;
  }
}

.home-logo {
  display: none;

  &.visible {
    display: block;
  }
}





// PERFORMANCE


@font-face {
  font-family: 'eurostile-variable';
  src: url('fonts/eurostile-variable.woff2') format('woff2-variations'),
       url('fonts/eurostile-variable.woff2') format('woff2');
  font-weight: 900;
}

@font-face {
  font-family: 'acumin-variable';
  src: url('fonts/AcuminVariableConcept.otf.woff2') format('woff2-variations'),
       url('fonts/AcuminVariableConcept.otf.woff2') format('woff2');
}

@font-face {
  font-family: 'acumin-regular';
  src: url('fonts/Acumin-Pro-Book.woff') format('woff');
}

@font-face {
  font-family: 'eurostile100';
  src: url('fonts/eurostile100.woff2') format('woff2');
  font-weight: 900;
}

@font-face {
  font-family: 'eurostile900';
  src: url('fonts/eurostile900.woff2') format('woff2');
  font-weight: 900;
}

.perf-landing-wrapper {
  height: 100vh;
  width: 100%;
  background-color: #FF5906;

  @include media-query($break-small) {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left:0;
    height: auto;
    overflow: hidden;
  }

  .blue-bg {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: -50%;
    z-index: 0;
    background-color: #A3CFFF;
    transition: transform 0.1s;

    @include media-query($break-small) {
      height: 200%;
      top: -50%;
    }
  }

  .content {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    position: relative;
    z-index: 1;
  }

  .title {
    white-space: nowrap;
  }

  .header {
    padding-top: $spacing-unit*2;
    font-size: 20px;
    font-family: 'acumin-variable', 'acumin-regular';
    font-variation-settings: 'wgth' 400, 'wdth' 107;
    text-transform: uppercase;
    text-align: center;

    br {
      display: none;
    }

    @include media-query($break-small) {
      font-size: 16px;
      line-height: 28px;

      span {
        display: none;
      }

      br {
        display: block;
      }
    }
  }

  .perf {
    font-size: 5.2vw;
    font-family: 'eurostile-variable';
  }

  .narrow {
    font-size: 5.2vw;
    font-family: 'eurostile100';
  }

  .variable {
    font-variation-settings: 'wdth' 900;
    transition: font-variation-settings 0.1s;
  }

  .landing-link, .landing-link a, .landing-link a:visited {
    color: black;
    font-size: 20px;
    font-family: 'acumin-variable', 'acumin-regular';
    font-variation-settings: 'wgth' 400, 'wdth' 107;
    text-transform: uppercase;

    @include media-query($break-small) {
      font-size: 16px;
    }
  }

  .landing-link a {
    @include media-query($break-small) {
      text-decoration: underline;
    }
  }

  a, a:visited {
    color: $dark-color;

    &:hover {
      opacity: 0.6;
    }
  }

}



// OPEXP

.landing-opexp {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: $dark-color;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  overflow: hidden;
}

.labo-landing-anim {
  flex: 1;
  cursor: pointer;
  margin: 250px 180px;

  @include media-query ($break-medium) {
    margin: 180px 120px;
  }
  @include media-query ($break-small) {
    margin: 120px 80px;
  }

}

.opexp-landing-link {
  height: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: $light-color;

  a, a:visited {
    color: $light-color;
    margin: 0 $spacing-unit;
    display: inline-block;
  }
}


.labo-landing-text {
  font-family: $serif-font;
  font-size: 262px;
  color: $light-color;
  display: inline-block;
  transform-origin: center;

  @include media-query ($break-medium) {
    font-size: 180px;
  }
  @include media-query ($break-small) {
    font-size: 120px;
  }
    
  
}





/// NEWSPAPER FOREVER


.landing-newspaper-forever {
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 100px 0;
  color: black;

  @media screen and (max-width: 900px) {
    padding: 60px 0;
  }

  .landing-content-desktop {
    flex: 1;
    width: 100%;
    max-width: 2000px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 50px 50px ;

    @media screen and (max-width: 900px) {
      display: none;
    }

    .title {
      flex: 1;
      height: auto;
      padding: 0 80px 0 0;
  
      img {
        display: block;
        width: 100%;
      }
    }
  }

  .landing-content-mobile {
    display: none;

    @media screen and (max-width: 900px) {
      flex: 1;
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
    }

    .title {
      width: 250px;

      img {
        width: 100%;
        display: block;
      }
    }

    .illu-mobile {
      display: block;
      width: 80%;
      margin: 20px 0;
    }
  }

  

  .illu {
    position: relative;
    width: 668px;
    height: 525px;

    @media screen and (max-width: 1250px) {
      width: 400px;
    height: 314px;
    }

    .illu-mobile {
      display: none;
    }

    img, canvas {
      position: absolute;
    }

    img {
      width: 100%;
    }

    .illu-front {
      pointer-events: none;
    }

    @media screen and (max-width: 900px) {
      width: 80%;
      height: auto;

      img {
        position: static;
      }

      .illu-mobile {
        display: block;
      }
      
      .illu-front, .illu-bg, canvas {
        display: none;
      }
    }
  }

  .nf-enter-link a {
    color: black;

    &:hover {
      opacity: 0.6;
    }
  }
}



/// PRINT-IT

.landing-print-it {
  width: 100%;
  height: 100vh;
  min-height: 100vh;
  max-height: 100vh;
  background-image: url(images/print-it-bg.gif);
  background-size: auto 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  
}

.print-it-title {
  width: 65%;
  height: auto;
  max-height: 90%;

  @include media-query($break-medium) {
    width: 95%;
  }

  img {
    display: block;
    width: 100%;
    max-height: 100%;
    margin-top: -30px;
  }
}

.landing-container {
  position: relative;
}


.print-it-landing-text {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  font-family: $sans-serif-font;
  font-size: 22px;
  color: $light-color;
  padding-top: $spacing-unit;
  text-align: center;
  line-height: 28px;

  span {
    font-size: 18px;
  }
}






/// BALI

.bali-landing-container {
  display: flex;
  background-color: #FFF6EA;
  min-height: 100vh;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}

.bali-logo {
  width: 200px;
}

.bali-illu {
  width: 100%;
  max-width: 800px;
}

.bali-title {
  width: 400px;
  margin-top: -60px;
}

.bali-enter {
  width: 90px;
  margin-top: 60px;
}





// SKIT

.skit-landing {
  background-color: white;
  font-family: monospace;
  color: black;
  font-size: 16px;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;

  span {
    display: block;
    width: 100%;
    text-align: center;
    margin:40px 0;
  }


  a, a:visited {
    color: black;
    cursor: pointer;
    text-decoration: underline;

    &:hover {
      opacity: 0.7;
    }
  }
}

.landing-description a:last-child {
  display: block;
  margin-top: 40px;
}



// COLLECTION

.home-logo {
  display: none;
}

.home-logo.visible {
  display: block;
}

.collection-landing-wrapper {
  width: 100%;
  height: 100vh;
  position: relative;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  overflow: hidden;

  .landing-link {
    z-index: 2;
    color: black;
    
    a, a:visited {
      color: black;
    }
  }
}

.collection-colors-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.collection-landing-section {
  display: block;
  flex: 1;

}

.collection-landing-section::after {
  content: "";
  display: block;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -2;
  pointer-events: none;
}

.collection-landing-section:hover::after {
  z-index: -1;
}

.collection-landing-section.paysages::after {
  background-color: #888888;
}
.collection-landing-section.messages::after {
  background-color: #972C2C;
}
.collection-landing-section.soleil::after {
  background-color: #ECDE58;
}
.collection-landing-section.encombrants::after {
  background-color: #CB256F;
}
.collection-landing-section.tuning::after {
  background-color: #B4D8D9;
}
.collection-landing-section.bouffe::after {
  background-color: #BDA78B;
}
.collection-landing-section.shopping::after {
  background-color: #26A7FF;
}
.collection-landing-section.sapins::after {
  background-color: #005B2E;
}
.collection-landing-section.chats::after {
  background-color: #FFE9B3;
}

.collection-logo {
  position: absolute;
  width: 200px;
  height: 200px;
  opacity: 0;
  top: 0;
  left: 0;
  transition: opacity 1s;
}

.logo-svg {
  animation: rotate 5s infinite linear;
}


@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(359deg);
  }
}

.collection-logo.visible {
  opacity: 1;
}