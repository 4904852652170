@font-face {
  font-family: 'CrimsonText';
  src: url('fonts/CrimsonText-Regular.woff2') format('woff2'),
       url('fonts/CrimsonText-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: 'CrimsonText';
  src: url('fonts/CrimsonText-Italic.woff2') format('woff2'),
       url('fonts/CrimsonText-Italic.woff') format('woff');
  font-weight: normal;
  font-style: italic;
  font-display: block;
}

@font-face {
  font-family: 'MadrasBold';
  src: url('fonts/MadrasBold.eot');
  src: url('fonts/MadrasBold.woff2') format('woff2'),
       url('fonts/MadrasBold.woff') format('woff'),
       url('fonts/MadrasBold.ttf') format('truetype'),
       url('fonts/MadrasBold.svg#MadrasExtraBold') format('svg'),
       url('fonts/MadrasBold.eot?#iefix') format('embedded-opentype');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: 'MadrasBold';
  src: url('fonts/MadrasBoldItalic.eot');
  src: url('fonts/MadrasBoldItalic.woff2') format('woff2'),
       url('fonts/MadrasBoldItalic.woff') format('woff'),
       url('fonts/MadrasBoldItalic.ttf') format('truetype'),
       url('fonts/MadrasBoldItalic.svg#MadrasExtraBold') format('svg'),
       url('fonts/MadrasBoldItalic.eot?#iefix') format('embedded-opentype');
  font-weight: normal;
  font-style: italic;
  font-display: block;
}

@font-face {
  font-family: 'MadrasRegular';
  src: url('fonts/MadrasRegular.eot');
  src: url('fonts/MadrasRegular.woff2') format('woff2'),
       url('fonts/MadrasRegular.woff') format('woff'),
       url('fonts/MadrasRegular.ttf') format('truetype'),
       url('fonts/MadrasRegular.svg#MadrasExtraBold') format('svg'),
       url('fonts/MadrasRegular.eot?#iefix') format('embedded-opentype');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}
